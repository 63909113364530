$(function() {

    if ($(window).scrollTop() > 80) {
        $('.navbar-main').addClass('filled');
    }

    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 80) {
            $('.navbar-main').addClass('filled');
        } else {
            $('.navbar-main').removeClass('filled');
        }
    });

    $('#navbarMain').on('show.bs.collapse', function () {
        $('.navbar.fixed-top').addClass('navbar-open');
        $('html').addClass('main-navbar-open');
    });

    $('#navbarMain').on('hide.bs.collapse', function () {
        $('.navbar.fixed-top').removeClass('navbar-open');
        $('html').removeClass('main-navbar-open');
    });

    // Add Overlay behind Dropdown Menu
    $('#navbarMain .dropdown').each(function () {
        if(!$(this).hasClass('language-switcher')) {
            $(this).on('show.bs.dropdown', function () {
                var $overlay = $('<span class="screen-overlay"></span>');
                $('body').append($overlay);
            });
        }

        $(this).on('hide.bs.dropdown', function () {
            $('.screen-overlay').remove();
        });
    });
});
