$(function() {
    //
    // Smooth Sroll
    //
    $('a[href*="#"]:not([href$="#"])').click(function() {

        if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'')
            && location.hostname === this.hostname
            && $(this).data('toggle') === undefined
            && $(this).data('slide') === undefined
            && !$(this).hasClass('skiplink')) {
            console.log('internal anchor link clicked --> smooth scroll to target');
            var $target = $(this.hash);
            $target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
            if ($target.length) {
                var targetOffset = $target.offset().top;
                var navbar = $('.navbar.fixed-top');
                var navbarHeight = navbar.outerHeight() + 80;

                if(navbar.length && targetOffset !== 0){
                    targetOffset -= navbarHeight;
                }
                $('html,body').animate({scrollTop: targetOffset}, 500);
                return false;
            }
        }
    });
});
