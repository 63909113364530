const images = document.getElementsByClassName('responsive-image');
for (let i = 0; i < images.length; i++) {
    let tmpWidth = 0,
        tmpHeight = 0,
        sources = images[i].getElementsByTagName('source'),
        img = images[i].getElementsByTagName('img');
    for (let j = 0; j < sources.length; j++) {
        if (window.matchMedia(sources[j].media).matches) {
            tmpWidth = sources[j].dataset.width;
            tmpHeight = sources[j].dataset.height;
            break;
        }
    }
    if(tmpWidth > 0 && tmpHeight > 0 ) {
        img[0].setAttribute('width', tmpWidth.toString());
        img[0].setAttribute('height', tmpHeight.toString());
    }
}

$(function() {
    $('html').removeClass('no-js');
});

$(document).ready(function() {
    $(window).scroll( function(){
        $('.textpic-image').each(fadeOpacity);
        $('.cta').each(fadeOpacity);

        $('.teaser-top').each( moveContentUp);

        $('.splide figure.image img').each( moveContentUp);
    });
});

function moveContentUp(){
    let top_of_element = $(this).offset().top;
    let bottom_of_window = $(window).scrollTop() + $(window).height();
    if( bottom_of_window > top_of_element ){
        $(this).animate({'margin-top': '0'},1000);
    }
}

function fadeOpacity() {
    let top_of_element = $(this).offset().top + 100;
    let bottom_of_window = $(window).scrollTop() + $(window).height();
    if( bottom_of_window > top_of_element ){
        $(this).animate({'opacity': '1'},1000);
    }
}
